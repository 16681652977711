.card {
    padding: 16px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;
    height: fit-content;
}
.dropdown {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    height: 30px!important;
    width: 100% !important;
    padding: 15px !important;
    border-radius: 40px !important;
    border: 0px solid #6A6A6A !important;
    cursor: pointer !important;
    background-color: #7aa1dd;
    margin-left: auto !important;
}
.dropdownPlaceholder {
    font-size: 14px;
    font-weight: 400;
    color: #2a65bf;
}

.barChart {
    display:flex;
    gap:6px;
    height:280px;
    background-color:#fff;
    width:90%;
    align-items:flex-end;
    justify-content:center;
    margin: 20px auto;
    margin-bottom: 40px;
    position: relative;
}
.bar {
    width: 40px;
    z-index: 2;
    position: relative;
    cursor: pointer;
}
.barTitle {
    background-color: #cad3eb;
    color: #2e58a2;
    min-width: fit-content;
    flex-shrink: 0;
    flex-grow: 1;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    border-radius: 10px;
    opacity: 0;
    transition: all .2s; 
}
.bar:hover .barTitle {
    display: flex !important;
    opacity: 1;
    z-index: 99999;
}


.yLabel {
    width: 100%;
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 1;  
}
.yLabel div {
    height: 1px;
    background-color: #CCCCCC;
    width: 100%;
}
.yLabels p {
    width: 26px;
    text-align: right;
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.performance {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 40% 40%;
    justify-content: space-between;
    align-items: center;
}

.performance p {
    width: 50%;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #6C6C6C;
}
.performance p span {
    font-weight: 700;
}
.performance div {
    display: flex;
    align-items: center;
    gap:12px
}
.progress {
    width: 120px;
    height: 120px;
}