.container {
    min-height: calc(100vh - 100px);
    background-color: #f4f7fa;
    padding: 20px;
    margin-top: 65px;
    display: grid;
    grid-template-columns: calc(70% - 24px) 30%;
    justify-content: space-between;
    align-items: stretch;
    transition: all .3s;
}

.poll {
    padding: 24px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 20px;
}

.cardHeader {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 16px;
}
.cardHeader img {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    object-fit: cover;
}
.cardHeader h3 {
    font-size: 16px;
    color: #383535;
    font-weight: 600;
}
.cardHeader h4 {
    color: #828282;
    font-size: 12px;
    font-weight: 400;
}
.time {
    font-size: 16px;
    font-weight: 500;
    color: #383535;
    margin-left: auto;
    display: flex;
    gap: 12px;
}
.time img {
    width: 24px;
    object-fit: contain !important;
    cursor: pointer;
}
.username {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 6px;
}
.online {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #01B829;
}

.pollContent {
    margin-bottom: 20px;
}
.pollContent h2 {
    font-size: 16px;
    color: #383535;
    font-weight: 400;
    margin-bottom: 16px;
}
.pollContent h5 {
    font-size: 16px;
    font-weight: 600;
    color: #383535;
    margin-bottom: 12px;
}
.options {
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.recentPolls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    height: 48px;
    border-radius: 12px;
}
.ongoingPolls {
    height: 48px;
    border-radius: 12px;
}
.options h3 {
    font-size: 20px;
    font-weight: 400;
    color: #2262C6;
    transition: all .3s;
}
.options h3 p {
    position: absolute;
    font-size: 16px;
}
.ongoingPolls h3 {
    font-size: 20px;
    font-weight: 400;
    color: #2262C6 !important;
    width: 100% !important;
    border: 1px solid #2262C6;
    background-color: #fff !important;
    cursor: pointer;
    padding: 0 !important;
    padding-left: 20px !important;
    position: relative;
}
.ongoingPolls span {
    height: 100%;
    background-color: rgba(130, 189, 229, 0.25);
    width: 0;
    border-radius: 12px;
    align-items: center;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    transition: all .3s;
}

.options h3:first-child {
    background-color: #DDE9FD;
    height: 100%;
    /* for poll percentage width */
    width: 0px;
    align-items: center;
    display: flex;
    padding-left: 12px;
    border-radius: 12px;
}
.options h3:last-child {
    color: #383535;
    min-width: fit-content;
}
.cardFooter {
    display: flex;
    gap: 22px;
    align-items: center;
}
.cardFooter div {
    color: #383535;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
}

.tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #E2E5EF;
    border-radius: 8px;
    padding: 4px;
    margin: 20px 0;
    margin-top: 0px;
}
.tabs div {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: #828282;
    border-radius: 6px;
    flex: 1;
    cursor: pointer;
}
.activeTab {
    color: #2262C6 !important;
    background-color: #fff !important;
}

.filterBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.filterBtn h2 {
    font-size: 20px;
    color: #383535;
    margin-bottom: 4px;
}
.filterBtn h3 {
    font-size: 12px;
    color: #828282;
    font-weight: 400;
}  
.filterBtn svg {
    cursor: pointer;
} 
.endOfPolls {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
}
.endOfPolls p {
    color: #828282;
}
.endOfPolls p:first-child {
    font-size: 20px;
    font-weight: 600;
}
.endOfPolls p:last-child {
    font-size: 12px;
    font-weight: 500;
}

.cardIcons {
    display: flex;
    align-items: center;
    gap: 32px;
    /* border-bottom: 1px solid #828282;
    padding-bottom: 12px;
    margin-bottom: 12px; */
}
.cardIcons div {
    height: 28px;
    border-radius: 5px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.cardIcons p {
    font-size: 14px;
    font-weight: 500;
}


/* for modal */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    /* height: 100vh; */
    /* width: 100vw; */
    width: calc(100vw + 10%);
    height: calc(100vh + 10%);
    background-color: rgba(0,0,0,0.4);
    z-index: 999998;
    display: none;
}
.modal {
    border-radius: 20px;
    padding: 80px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(55vw,-50%);
    background-color: #fff;
    transition: all .3s;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.editModal {
    border-radius: 20px;
    padding: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(55vw,-50%);
    background-color: #fff;
    transition: all .3s;
    z-index: 999999;
    min-width: 50vw;
    gap: 20px;
}
.modal h2 {
    font-size: 45px;
    color: #000;
    margin-bottom: 24px;
}
.modal h3 {
    font-size: 20px;
    font-weight: 400;
    color: #383535;
    margin-bottom: 40px;
    width: 60%;
    line-height: 150%;
}
.footerBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
 }
.footerBtns button {
    height: 48px;
    border-radius: 12px;
    border: 1px solid #2262C6;
    padding: 0px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}
.saveBtn {
    background-color: #2262C6;
    color: #fff;
}   
.cancelBtn {
    color: #2262C6;
    background-color: #fff;
}
/* ----------- */

/* responsive design */
@media(max-width: 500px) {
    .container {
       min-height: calc(100vh - 60px);
       padding: 16px;
       margin-left: 0px !important;
       margin-top: 95px;
       display: grid;
       grid-template-columns: 100%;
    }
    .filterBtn h2 {
        font-size: 16px;
    }

    .poll {
        padding: 12px;
        margin-bottom: 12px;
    }
    .cardHeader img {
        height: 32px;
        width: 32px;
    }
    .cardHeader h3 {
        font-size: 14px;
    }
    .time {
        font-size: 12px;
    }
    .time img {
        width: 18px;
    }
    .username {
        margin-bottom: 4px;
    }
    
    .pollContent {
        margin-bottom: 16px;
    }
    .pollContent h2 {
        font-size: 14px;
        margin-bottom: 12px;
    }
    .pollContent h5 {
        font-size: 14px;
        margin-bottom: 8px;
    }
    .recentPolls {
        gap: 20px;
        height: 26px;
        border-radius: 4px;
    }
    .ongoingPolls {
        height: 26px;
        border-radius: 4px;
    }
    .options h3 {
        font-size: 14px;
    }
    .ongoingPolls h3 {
        font-size: 14px;
    }
    .ongoingPolls span {
        border-radius: 4px;
    }
    
    .options h3:first-child {
        background-color: #DDE9FD;
        height: 100%;
        /* for poll percentage width */
        width: 0px;
        align-items: center;
        display: flex;
        padding-left: 12px;
        border-radius: 4px;
    }
    .options h3:last-child {
        color: #383535;
        min-width: fit-content;
    }
    .endOfPolls p:first-child {
        font-size: 14px;
    }
    .endOfPolls p:last-child {
        font-size: 12px;
    }
    .cardIcons {
        display: flex;
        align-items: center;
        gap: 32px;
        /* border-bottom: 1px solid #828282;
        padding-bottom: 12px;
        margin-bottom: 12px; */
    }
    .cardIcons div {
        height: 24px;
    }
    .cardIcons p {
        font-size: 12px;
        font-weight: 500;
    }
    .tabs div {
        font-size: 12px;
    }
    .modal {
        border-radius: 20px;
        padding: 0px;
        width: 80%;
        padding: 40px 0;
        z-index: 999999;
    }
    .modal h2 {
        font-size: 20px;
    }
    .modal h3 {
        font-size: 16px;
    }
    .footerBtns button {
        height: 34px;
        border-radius: 8px;
        padding: 0px 18px;
        font-size: 12px;
    }
}




.postInputArea {
    border-radius: 8px;
    background-color: #F3F5F8;
    width: 100%;
    min-height: 95px;
    padding: 12px 14px;
    margin-bottom: 10px;
    transition: all .3s;
}
.titleInput {
    background-color: transparent;
    border: none;
    color: #383535;
    font-size: 19px;
    font-weight: 600 !important;
    margin-bottom: 8px;
    outline: none;
    width: 100%;
}
.postInputArea textarea, .descriptionInput {
    background-color: transparent;
    border: none;
    outline: none;
    flex: 1;
    font-size: 15px;
    color: #383535;
    margin-bottom: 8px;
    resize: none;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-weight: 500;
}
.postInputArea textarea::-webkit-scrollbar {
    display: none;
}
.postInputArea input[type="text"]::placeholder {
    color: #828282;
}

.profileIcon {
    height: 78px;
    width: 78px;
    border-radius: 50%;
    background-color: #f9f9f9;
}
.profileIcon img {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    object-fit: contain;
}
.postInput {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

/* for poll creattion */
.createPoll input {
    background-color: #fff !important;
    height: 38px;
    font-weight: 400 !important;
    font-size: 16px;
    padding: 0px 12px;
    border-radius: 4px !important;
    border: none;
    outline: none;
    width: 100%;
    margin-bottom: 12px;
}
.createPoll button {
    height: 38px;
    font-size: 16px;
    color: #000;
    border-radius: 4px;
    background-color: #fff;
    padding: 0px 12px;
    border: none;
    width: 100%;
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 4px;
}
/* ----- */



/* edit and delete dropdown */
.editDeleteDropdown {
    position: absolute;
    left: -80px;
    bottom: -40px;
    height: 40px;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    background-color: #fff;
    display: block;
    margin-left: auto !important;
}
.editDeleteDropdown div  {
    padding: 8px 18px;
    cursor: pointer;
    text-align: left;
    transition: all .2s;
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
}
.editDeleteDropdown div:hover {
    background-color: #f9f9f9;
}
.editDeleteDropdown svg {
    width: 20px;
}