.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 9999999999;
}
.overlay {
    z-index: 9999999998;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    /* height: 100vh; */
    /* width: 100vw; */
    width: calc(100vw + 10%);
    height: calc(100vh + 10%);
}