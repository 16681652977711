.container {
    background-color: #f4f7fa;
    padding: 20px 40px;
    margin-left: 120px;
    margin-top: 100px;
    min-height: calc(100vh);
  }
  .header {
    background-color: #2262c6;
    border-radius: 20px 20px 0 0;
    width: 100%;
    padding: 0px 26px;
    height: 70px;
    display: flex;
    align-items: center;
    gap: 10px;
    /* margin-bottom: 25px; */
  }
  .header h1 {
    font-size: 30px;
    font-weight: 600;
    color: #fff;
  }

.createSurvey input, .createSurvey textarea {
    width: 100%;
    outline: none;
    border: none;
    padding: 12px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 400;
    border: 2px solid #6A6A6A;
    margin-bottom: 24px;
}
.createSurvey input[type='date'] {
    width: fit-content;
}
.addQtn {
    display: block;
    margin-top: 30px;
    background-color: #2262C6;
    color: #F0F2F8;
    height: 48px;
    border-radius: 20px;
    padding: 0px 30px;
    border: none;
    font-size: 22px;
    font-weight: 500;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    cursor: pointer;
}
.submitBtn {
    display: block;
    margin-top: 30px;
    background-color: #2262C6;
    color: #F0F2F8;
    height: 48px;
    border-radius: 12px;
    width: 200px;
    border: none;
    font-size: 20px;
    font-weight: 500;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.createSurvey label {
    color: #727272;
    font-size: 15px;
    font-weight: 500;
}

.question {
    margin: 6px 0;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 12px;
}
.question span {
    font-weight: 600;
}


.createPoll input {
    background-color: #fff !important;
    height: 38px;
    font-weight: 400 !important;
    font-size: 16px;
    padding: 0px 12px;
    border-radius: 4px !important;
    border: none;
    outline: none;
    width: 100%;
    margin-bottom: 12px;
}
.createPoll button {
    height: 38px;
    font-size: 16px;
    color: #000;
    border-radius: 4px;
    background-color: #fff;
    padding: 0px 12px;
    border: none;
    width: 100%;
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 4px;
}

.createSurvey {
    width: 100%;
    padding: 18px;
    border-radius: 0 0 16px 16px;
    background: #E0EAF9;
}
.createSurvey textarea {
    background-color: #fff;
    border-radius: 16px;
    padding: 14px;
    border: none;
    color: #8b8b8b;
    font-size: 16px;
    width: 100%;
    margin-top: 6px;
    outline: none;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    resize: none;
}