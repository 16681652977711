.superContainer {
    display: grid;
    grid-template-columns: 68% 28%;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #f4f7fa;
    padding: 20px 40px;
    margin-left: 120px;
    margin-top: 65px;
}

.container {
    min-height: calc(100vh - 100px);
    justify-content: space-between;
    align-items: stretch;
    transition: all .3s;
}

.container input {
    padding: 0px 15px;
    height: 44px;
    border: 2px solid #6A6A6A;
    border-radius: 6px;
    outline: none;
    width: 100%;
}
.container textarea {
    padding: 15px;
    border: 2px solid #6A6A6A;
    border-radius: 6px;
    outline: none;
    width: 100%;
    resize: none;
}
.createEmailBtn {
    background-color: #2262C6;
    border-radius: 20px;
    color: #fff;
    border: none;
    width: 180px;
    height: 44px;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 30px;
}
.email h3 {
    font-size: 20px;
    font-weight: 400;
    min-width: 100px;
}
.emails {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 30px;
}
.email {
    display: flex;
    align-items: center;
    gap: 20px;
}
.email button, .createBtn {
    background-color: #518EF8;
    border-radius: 20px;
    color: #fff;
    border: none;
    min-width: 180px;
    height: 38px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.milestones {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 22px;
    margin-bottom: 40px;
}
.milestone {
    flex: 1;
}
.milestone h4 {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 12px;
}

.dropdown {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    height: 50px!important;
    min-width: 230px !important;
    padding: 15px !important;
    border-radius: 8px !important;
    border: 0px solid #6A6A6A !important;
    cursor: pointer !important;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px
}
.dropdownPlaceholder {
    font-size: 15px;
    font-weight: 400;
    color: #6A6A6A;
}

.tags {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    align-items: center;
}
.tag {
    border: 1px solid #b0b2b4;
    padding: 0px 14px;
    display: flex;
    align-items: center;
    gap: 14px;
    height: 40px;
    width: fit-content;
    background-color: #f8fafd;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

/* new design */
.card {
    padding: 16px;
    border-radius: 16px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    width: 100%;
    height: fit-content;
}

.superContainer h1 {
    font-size: 28px;
    color: #2362C6;
    font-weight: 700;
    margin-bottom: 2px;
}
.superContainer h3 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #6C6C6C;
}
.content {
    justify-content: space-between;
    align-items: flex-start;
}

.trendsHeader {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}


.prominant {
    margin-top: 28px;
}
.prominant h3 {
    margin: 0;
    color: #6C6C6C;
    font-weight: 600;
    font-size: 18px;
}
.prominantTopics {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-height: 475px;
    overflow-y: scroll;
    scrollbar-width: none;
}

.prominantTopics::-webkit-scrollbar {
    display: none;
}
.prominantTopic .progressContainer {
    height: 20px;
    width: 100%;
    background-color: #DFE9F7;
    border-radius: 16px;
    margin-bottom: 2px;
}
.prominantTopic .progressWidth {
    background: linear-gradient(to right, #2262C6, #BBDEFF);
    height: 100%;
    border-radius: 16px;
}
.prominantTopic h5 {
    font-size: 14px;
    color: #8D8D8D;
    font-weight: 400;
}

.broadcast footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.broadcastLink {
    height: 52px;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 14px;
    background-color: #E0EAF9;
    cursor: pointer;
}
.broadcastLink p {
    font-size: 17px;
    color: #343434;
    font-weight: 400;   
}
.broadcastIcons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 50px;
}
.userIcon {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    object-fit: cover;
}