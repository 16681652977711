/* for dropwdown -- */
.dropdownContainer {
    flex: 1;
    /* height: 32px !important;
    border-radius: 20px !important;
    cursor: pointer !important; */
}
.dropdown {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    height: 48px!important;
    width: 100% !important;
    padding: 0px 12px !important;
    border-radius: 0px !important;
    border: none !important;
    cursor: pointer !important;
    background-color: #EDEFF6 !important;
}
.whiteDropdown {
    height: 44px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 0 16px !important;
    border-radius: 8px !important;
    cursor: pointer !important;
    border: 1px solid #D0D1D9 !important;
    background-color: #fff !important;
    width: 100%;
}
.dropdownPlaceholder {
    font-size: 20px !important;
    font-weight: 400 !important;
    color: #828282 !important;
}
.dropdownMenu {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    margin: 0 !important;
    gap: 0 !important;
    align-items: stretch !important;
    border-radius: 6px !important;
    border: 1px solid #D0D1D9;
}
/* --------------- */