.uploadImage {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
}
.uploadedImage {
    width: 115px;
    background-color: #dcdcdc;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 30px;
    min-width: fit-content;
    cursor: pointer;
    transition: all .2s;
}
.uploadedImage:hover {
    background-color: #d3d3d3;
}
.uploadedImage p {
    color: #2262C6;
    font-size: 12px;
    font-weight: 500;
    flex-shrink: 0;
}
.uploadError {
    color: red;
    font-size: 12px;
    font-weight: 400;
    flex-shrink: 0;
}

.fileUploadWrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 115px;
    height: 110px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px dashed #828282;
  }
  .fileUploadWrapper p {
      font-weight: 400;
      color: #2262C6;
      font-size: 10px;
  }
  .fileUploadWrapper div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .fileUploadWrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 110px;
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
}

.headerBtns {
    display: flex;
    gap: 26px;
    align-items: center;
}
.headerBtns div {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}
.headerBtns p {
    font-size: 16px;
    font-weight: 400;
    color: #383535;
}

.headerBtns button {
    background-color: #2262C6;
    height: 48px;
    width: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    color: #fff;
    border: none;
    margin-left: auto;
}
.headerBtns img {
    height: 32px;
    width: 32px;
    object-fit: contain;
}