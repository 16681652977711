.container {
    min-height: calc(100vh - 100px);
    background-color: #f4f7fa;
    padding: 20px;
    margin-left: 120px;
    margin-top: 100px;
    display: grid;
    grid-template-columns: calc(70% - 24px) 30%;
    justify-content: space-between;
    align-items: stretch;
    transition: all .3s;
}

.filterBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
}
.filterBtn h2 {
    font-size: 20px;
    color: #383535;
    /* margin-bottom: 4px; */
}
.filterBtn h3 {
    font-size: 12px;
    color: #828282;
    font-weight: 400;
}  
.filterBtn svg {
    cursor: pointer;
} 

.card {
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #fff;
}


.notifications {
    margin-bottom: 18px;
}
.notifications h3 {
    font-size: 12px;
    font-weight: 400;
    color: #828282;
    margin-bottom: 18px;
}

.notification {
    display: flex;
    align-items: flex-start;
    padding: 16px 4px;
    gap: 10px;
    cursor: pointer;
    transition: all .2s;
}
.notification:hover {
    background-color: #f5f5f5;
}

.notification:not(:last-child) {
    border-bottom: 1px solid #cbcbcb;
}
.notificationImage {
    background-color: #2262C6;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    flex-shrink: 0;
}
.notificationImage img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
}

.notification h2 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    color: #383535;
    margin-bottom: 6px;
}
.notification h4 {
    font-size: 14px;
    font-weight: 400;
    color: #828282;
    line-height: 130%;
}



@media (max-width:500px) {
    .container {
        min-height: calc(100vh - 60px);
        padding: 16px;
        margin-left: 0px !important;
        margin-top: 60px;
        display: grid;
        grid-template-columns: 100%;
        background-color: #fff;
    }
    .card {
        padding: 0px;
        margin-bottom: 12px;
    }
    
    .notification {
        padding: 16px 4px;
    }
}