.container {
  background-color: #f4f7fa;
  padding: 20px 40px;
  margin-left: 120px;
  margin-top: 100px;
  min-height: calc(100vh);
}
.childContainer {
  display: grid;
    grid-template-columns: 62% 34%;
    justify-content: space-between;
    align-items: flex-start;
}
.header {
  background-color: #2262C6;
  border-radius: 16px;
  width: 100%;
  padding: 0px 26px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.header h1 {
  font-size: 30px;
  font-weight: 600;
  color: #fff;
}
.header button {
  background-color: #fff;
  display: flex;
  align-items: center;
  color: #2262C6;
  font-size: 18px;
  font-weight: 400;
  border-radius: 20px;
  border: none;
  outline: none;
  padding: 12px 20px;
  gap: 10px;
  cursor: pointer;
}
.createSurvey {
  height: 50vh;
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  padding: 0px 21px;
  border: none;
  font-size: 18px;
  font-weight: 400;
  color: #2262c6;
  cursor: pointer;
}
.tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  margin-bottom: 20px;
}
.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.modalHeader svg {
  cursor: pointer;
}
.modalHeader h2 {
  font-size: 22px;
  font-weight: 600;
  border-radius: 15px;
}

.modalFooter {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}
.modalFooter button {
  border: 1px solid #1e71f2;
  border-radius: 30px;
  width: 140px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}
.createSurvey input[type="text"] {
  width: 100%;
  border: none;
  border: 1px solid #000000;
  border-radius: 10px;
  height: 40px;
  padding: 0px 25px;
  font-size: 15px;
  outline: none;
  font-weight: 400;
  margin-bottom: 18px;
}
.createSurvey input[type="text"]::placeholder {
  color: #8c8c8c;
}

.tabs div {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
  color: #2262c6;
  background-color: #D3E0F4;
  border-radius: 20px;
  flex: 1;
  cursor: pointer;
}
.activeTab {
  color: #fff !important;
  background-color: #2262c6 !important;
}

.container ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 80px;
}
.container ul li {
  height: 60px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 42px;
  padding-right: 20px;
  cursor: pointer;
}
.container li h2 {
  font-size: 20px;
  font-weight: 500;
  color: #5d6675;
}
.container li h3 {
  color: #5d6675;
  font-size: 18px;
  font-weight: 400;
}
.container li h3 span {
  font-weight: 600 !important;
  color: #374253 !important;
}
.container li button {
  font-size: 18px;
  font-weight: 400;
  color: #518ef8;
  background-color: #e9eff9;
  height: 36px;
  border-radius: 20px;
  padding: 0px 24px;
  border: none;
  min-width: 120px;
}
.activeItem {
  background-color: #e9eff9 !important;
}
.activeItem h2 {
  color: #374253 !important;
}
.activeItem button {
  background-color: #518ef8 !important;
  color: #fff !important;
}

.pieChart {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  gap: 22px;
  margin: 40px auto;
}
.pieChart h4 {
  font-size: 20px;
  font-weight: 600;
  color: #374253;
}

.stats h4 {
  color: #000;
  font-size: 20px;
  font-weight: 500;
}

.statsCards {
  display: flex;
  overflow-x: scroll;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 55px;
  padding-bottom: 20px;
  padding-left: 4px;
  padding-right: 4px;
  gap: 40px;
}
.statsCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  height: 180px;
  min-width: 180px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  background-color: #fff;
}
.statsCard h2 {
  font-size: 35px;
  font-weight: 600;
  max-height: 50px !important;
  min-height: 50px;
}
.statsCard h3 {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #6a6a6a;
  margin: 0;
}

.statsCards::-webkit-scrollbar {
  display: none;
}

.statsCards {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container aside {
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
  height: fit-content;
}
.container aside h2 {
  font-weight: 700;
  font-size: 34px;
  color: #2362C6;
}
.broadcastIcons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;
}
.userIcon {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: cover;
}