.container {
    padding: 24px;
    margin-left: 215px;
    margin-top: 76px;
}
.header {    
    background-color: #2262C6;
    border-radius: 20px 20px 0 0;
    width: 100%;
    padding: 0px 26px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}
.header h1 {
    font-size: 28px;
    font-weight: 600;
    color: #fff;
}
.header button {
    height: 48px;
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    padding: 0px 21px;
    border: none;
    font-size: 18px;
    font-weight: 400;
    color: #2262C6;
    cursor: pointer;
}


.table {
    text-align: left;
    width: 100%;
}
.table, .table th, .table td {
    border: 1px solid #E0E0E0;
    border-collapse: collapse;
}
.table th {
    font-size: 18px;
    font-weight: 400;
    padding: 12px 20px;
}
.table td {
    font-size: 18px;
    font-weight: 400;
    height: 70px;
    padding: 0px 20px;
}

/* modal */
.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
}
.modalHeader h2 {
    font-size: 22px;
    font-weight: 600;
}
.modal input, .modal textarea {
    width: 100%;
    display: block;
    padding: 15px;
    border: none;
    outline: none;
    border: 2px solid #6A6A6A;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 18px;
}
.modalHeader svg {
    cursor: pointer;
}

.dropdown {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    height: 40px!important;
    width: 100% !important;
    padding: 15px !important;
    border-radius: 6px !important;
    border: 2px solid #6A6A6A !important;
    cursor: pointer !important;
}
.modal footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 60px;
}
.modal button {
    background-color: #518EF8;
    height: 40px;
    border-radius: 20px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    min-width: 180px;
    border: none;
    cursor: pointer;
}