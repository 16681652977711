.dateInput {
    position: relative;
    width: 116px;
}
.dateInput input {
    width: 116px;
    height: 20px;
    opacity: 0;
    
}
.dateInput input::-webkit-calendar-picker-indicator {
    width: 116px !important;
    height: 20px;
    position: absolute;
    top: 0;
    left: 0;
}
.dateInput div {
    display: flex;
    align-items: center;
    position: absolute;
    padding: 0px 12px;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: #EDEFF6;
}