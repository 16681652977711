.header {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin-bottom: 36px;
}
.header .card {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    padding: 28px 0px;
    transition: width .2s;
    gap: 16px;
    max-height: 220px;
    min-height: 220px;
}
.header .cardHeader {
    position: absolute;
    left: 12px;
    top: 12px;
}
.cardOpenIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    cursor: pointer;
}
.card {
    width: 100%;
    height: fit-content;
}

.header .card h3 {
    font-size: 24px;
    color: #6C6C6C;
    font-weight: 600;
    margin: 0;
}
.header .card h4 {
    font-weight: 400;
    color: #6C6C6C;
    font-size: 10px;
}
.progress {
    width: 150px;
    height: 150px;
}


.dropdown {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    height: 30px!important;
    width: 150px !important;
    padding: 12px !important;
    border-radius: 50px !important;
    border: 0px solid #6A6A6A !important;
    cursor: pointer !important;
    box-shadow: none !important;
    background-color: #BBD4FE !important;
}
.dropdownPlaceholder {
    font-size: 12px;
    font-weight: 500;
    color: #6494DF;
}

.cardFilterContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}