.container {
    display: grid;
    grid-template-columns: 55% 42%;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #f4f7fa;
    padding: 20px 40px;
    margin-left: 120px;
    margin-top: 100px;
    min-height: calc(100vh);
}
.container h1 {
    font-size: 28px;
    color: #2362C6;
    font-weight: 700;
    margin-bottom: 2px;
}
.container h3 {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    color: #6C6C6C;
}
.broadcast {
    padding: 18px;
    border-radius: 16px;
    background: #E0EAF9;
    margin-top: 16px;
}
.textarea {
    background-color: #fff;
    border-radius: 16px;
    padding: 14px;
    border: none;
    color: #8b8b8b;
    font-size: 16px;
    width: 100%;
    margin-top: 6px;
    outline: none;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    resize: none;
}

.textarea::placeholder {
    color: #8b8b8b;
}
.broadcast button {
    height: 47px;
    width: 112px;
    background-color: #2262C6;
    border: none;
    border-radius: 16px;
    color: #fff;
    display: block;
    font-size: 15px;
    cursor: pointer;
    transition: all .2s;
    text-transform: uppercase;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.broadcast button:hover {
    background-color: #1250b3;
}
.userIcon {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    object-fit: cover;
}
.broadcast label {
    color: #727272;
    font-size: 15px;
    font-weight: 500;
}
.broadcast footer {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-top: 24px;
}

.broadcastList h3 {
    font-size: 25px;
    color: #616161;
    font-weight: 500;
    margin-bottom: 12px;
}

.table {
    text-align: left;
    width: 100%;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    border-radius: 16px;
    border-collapse: collapse;
    overflow: hidden;
}
.table thead {
    background-color: #DEE7F6;
    height: 58px;
}
.table thead th {
    padding: 0px 22px;
    font-size: 22px;
    color: #111111;
    font-weight: 400;
}
.table tbody {
    background-color: #fff;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}
.table tbody td {
    padding: 0px 22px;
    height: 58px;
}
.broadcastStatus {
    height: 32px;
    width: 84px;
    border: 1px solid #488DFF;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    font-size: 14px;
    font-weight: 500;
}
.liveStatus {
    cursor: pointer;
    position: relative;
    transition: all .2s;
}

.endBtn {
    position: absolute;
    top: -10px;
    right: -8px;
    display: none;
}
.liveStatus:hover {
    background-color: rgb(255, 246, 246);
}
.liveStatus:hover .endBtn {
    display: block;
}

.liveDot {
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: #FF0000;
    margin-top: 2px;
}

.dateInput {
    position: relative;
    width: 200px;
}
.dateInput input {
    min-width: 200px;
    height: 48px;
    opacity: 0;
    
}
.dateInput input::-webkit-calendar-picker-indicator {
    min-width: 200px !important;
    height: 48px;
    position: absolute;
    top: 0;
    left: 0;
}
.dateInput p {
    color: #192A3E;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}
.dateContent {
    position: absolute;
    pointer-events: none;
    top: 0;
    left: 0;
    border-radius: 16px;
    background-color: #fff;
    height: 47px;
    padding: 0px 16px;
    width: 198px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #8B8B8B;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.dateContent h3 {
    margin: 0;
    color: #8b8b8b;
    font-size: 15px;
    font-weight: 400;
}
.listLoader {
    width: 100%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 0px 20px 20px;
}

/* for modal */
.mmodalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    /* height: 100vh; */
    /* width: 100vw; */
    width: calc(100vw + 10%);
    height: calc(100vh + 10%);
    background-color: rgba(0,0,0,0.4);
    z-index: 999998;
    display: none;
}
.mmodal {
    border-radius: 20px;
    padding: 80px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(58vw,-50%);
    background-color: #fff;
    transition: all .3s;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mmodal h2 {
    font-size: 45px;
    color: #000;
    margin-bottom: 24px;
}
.mmodal h3 {
    font-size: 20px;
    font-weight: 400;
    color: #383535;
    margin-bottom: 40px;
}
.mfooterBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
 }
.mfooterBtns button {
    height: 48px;
    border-radius: 12px;
    border: 1px solid #2262C6;
    padding: 0px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}
.saveBtn {
    background-color: #2262C6;
    color: #fff;
}   
.cancelBtn {
    color: #2262C6;
    background-color: #fff;
}
/* ----------- */

