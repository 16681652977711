.container {
    min-height: calc(100vh - 100px);
    background-color: #f4f7fa;
    padding: 20px;
    margin-top: 65px;
    display: grid;
    grid-template-columns: calc(70% - 24px) 30%;
    justify-content: space-between;
    align-items: stretch;
    transition: all .3s;
}

.container main header {
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    padding: 24px;
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 40px;
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.profileIcon {
    height: 78px;
    width: 78px;
    border-radius: 50%;
    background-color: #f9f9f9;
}
.profileIcon img {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    object-fit: contain;
}

.postInput {
    flex: 1;
}
.postInput h3 {
    font-size: 16px;
    color: #383535;
    font-weight: 400;
}
.postInputArea {
    border-radius: 8px;
    background-color: #F3F5F8;
    width: 100%;
    min-height: 95px;
    padding: 12px 20px;
    margin-bottom: 10px;
    transition: all .3s;
}

.titleInput {
    background-color: transparent;
    border: none !important;
    color: #383535;
    font-size: 20px;
    font-weight: 600 !important;
    margin-bottom: 8px;
    outline: none;
    width: 100%;
}
.postInputArea textarea, .descriptionInput {
    background-color: transparent;
    border: none;
    outline: none;
    flex: 1;
    font-size: 15px;
    color: #383535;
    margin-bottom: 8px;
    resize: none;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-weight: 500;
}
.postInputArea textarea::-webkit-scrollbar {
    display: none;
}
.postInputArea input[type="text"]::placeholder {
    color: #828282;
}

.headerBtns {
    display: flex;
    gap: 26px;
    align-items: center;
}
.headerBtns div {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}
.headerBtns p {
    font-size: 16px;
    font-weight: 400;
    color: #383535;
}

.headerBtns button {
    background-color: #2262C6;
    height: 48px;
    width: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    color: #fff;
    border: none;
    margin-left: auto;
}
.headerBtns img {
    height: 32px;
    width: 32px;
    object-fit: contain;
}
.profileIconSm {
    display: none;
}
.profileIconLg {
    display: block;
}

/* for poll creattion */
.createPoll input {
    background-color: #fff !important;
    height: 38px;
    font-weight: 400 !important;
    font-size: 16px;
    padding: 0px 12px;
    border-radius: 4px !important;
    border: none;
    outline: none;
    width: 100%;
    margin-bottom: 12px;
}
.createPoll button {
    height: 38px;
    font-size: 16px;
    color: #000;
    border-radius: 4px;
    background-color: #fff;
    padding: 0px 12px;
    border: none;
    width: 100%;
    cursor: pointer;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 4px;
}
/* ----- */

.activePostType p {
    color: #2262C6 !important;
}
.anonymousBtn {
    background-color: #fff !important;
    border: 1px solid #1E71F2 !important;
    color: #1E71F2 !important;
}

.filterBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}
.filterBtn h2 {
    font-size: 20px;
    color: #383535;
    margin-bottom: 4px;
}
.filterBtn h3 {
    font-size: 12px;
    color: #828282;
    font-weight: 400;
}  
.filterBtn svg {
    cursor: pointer;
} 

/* card */
.card {
    padding: 24px;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    cursor: pointer;
    transition: all .2s;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
}
.card:hover {
    border-color: #2262C6;
}
.cardHeader {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 16px;
}
.cardHeader img {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    object-fit: cover;
}
.cardHeader h3 {
    font-size: 16px;
    color: #383535;
    font-weight: 600;
    display: block;
}
.cardHeader h4 {
    color: #828282;
    font-size: 12px;
    font-weight: 400;
    display: block;
}
.time {
    display: flex;
    gap: 10px;
    font-size: 16px;
    font-weight: 500;
    color: #383535;
    margin-left: auto;
}
.time svg {
    margin-top: -2px;
}
.username {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 6px;
}
.online {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #01B829;
}
.cardBody {
    margin-bottom: 20px;
}
.cardBody h5 {
    font-size: 16px;
    font-weight: 600;
    color: #383535;
    margin-bottom: 12px;
}
.cardBody p {
    font-size: 16px;
    font-weight: 400;
    color: #383535;
    line-height: 130%;
}
.cardIcons {
    display: flex;
    align-items: center;
    gap: 32px;
    border-bottom: 1px solid #828282;
    padding-bottom: 12px;
    margin-bottom: 12px;
}
.cardIcons div {
    height: 28px;
    border-radius: 5px;
    padding: 0px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.cardIcons p {
    font-size: 14px;
    font-weight: 500;
}
.cardFooter {
    display: flex;
    gap: 22px;
    align-items: center;
}
.cardFooter div {
    color: #383535;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    gap: 8px;
    align-items: center;
    cursor: pointer;
}

/* comments ------ */
.comments {
    margin-top: 16px;
}
.commentsHeader {
    /* background-color: #e9eff9;
    padding: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #2262C6;
    display: flex;
    align-items: center;
    justify-content: space-between; */
    height: 14px;
}
.comments textarea {
    border: none;
    outline: none;
    padding: 8px 12px;
}
.commentInput {
    font-size: 14px !important;
    resize: none;
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    background-color: #f2f2f2;
    font-weight: 500;
    padding: 0;
    height: 36px;
}
.commentInput::placeholder {
    color: #828282;
}
.comments form {
    display: flex;
    align-items: center;
    gap: 20px;
}
.commentBtn {
    background-color: #2262C6;
    width: 96px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: #fff;
    border: none;
    margin-left: auto;
}
.viewReplies {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: #2262C6;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
}
.repliesOnComment {
    border-left: 1px solid #c0c0c0;
    padding-left: 20px;
    margin-bottom: 20px;
}
.replyOnComment {
    border-bottom: 1px solid #c0c0c0;
    margin-bottom: 16px;
}
/* --------------- */

.repost {
    border: 1px solid #828282;
    border-radius: 8px;
    padding: 24px;
}
.repostIcon {
    display: flex;
    align-items: center;
    gap: 10px;
}
.repostIcon img {
    width: 18px;
    object-fit: contain;
}
.repostIcon p { 
    font-size: 16px;
    font-weight: 500;
    color: #2262C6;

}


.fileUploadWrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 115px;
    height: 110px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px dashed #828282;
  }
  .fileUploadWrapper p {
      font-weight: 400;
      color: #2262C6;
      font-size: 10px;
  }
  .fileUploadWrapper div {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .fileUploadWrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 110px;
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
}


.tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
}
.tabs div {
    height: 61px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    color: #828282;
    background-color: #fff;
    border-radius: 0px;
    flex: 1;
    cursor: pointer;
  }
.activeTab {
    color: #2262C6 !important;
    background-color: #dee7f5 !important;
}

.uploadImage {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.uploadedImage {
    width: 115px;
    background-color: #dcdcdc;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    align-items: center;
    gap: 30px;
    min-width: fit-content;
    cursor: pointer;
    transition: all .2s;
}
.uploadedImage:hover {
    background-color: #d3d3d3;
}
.uploadedImage p {
    color: #2262C6;
    font-size: 12px;
    font-weight: 500;
    flex-shrink: 0;
}
.uploadError {
    color: red;
    font-size: 12px;
    font-weight: 400;
    flex-shrink: 0;
}
.postImage {
    width: 100%;
    height: 300px;
    border-radius: 4px;
    margin-top: 12px;
    object-fit: cover;
    object-position:top;
    cursor: pointer;
}
.postFile {
    width: 200px;
    margin-top: 12px;
    display: flex;
}
.postFile div {
    height: 50px;
    width: 70px;
    background-color: #2262C6;
    border-radius: 10px 0px 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.postFile a {
    font-weight: 12px;
    font-weight: 500;
    text-align: center;
    color: #2262C6;
    border: 1px solid #2262C6;
    width: 100%;
    border-radius: 0 10px 10px 0px;
    padding: 6px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.reportIcon {
    margin-left: auto;
}

/* for modal */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    /* height: 100vh; */
    /* width: 100vw; */
    width: calc(100vw + 10%);
    height: calc(100vh + 10%);
    background-color: rgba(0,0,0,0.4);
    z-index: 999998;
    display: none;
}
.modal {
    border-radius: 20px;
    padding: 80px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(55vw,-50%);
    background-color: #fff;
    transition: all .3s;
    z-index: 999999999;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.reportModalOverlay {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999999999;
}
.reportModal {
    transform: translate(-50%,-50%);
}
.repostModal {
    border-radius: 20px;
    padding: 28px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(55vw,-50%);
    background-color: #fff;
    transition: all .3s;
    z-index: 999999;
    width: 55vw;
    max-height: 90vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.reportReasons {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-right: auto;
    margin-bottom: 40px;
}
.reportReasons div {
    display: flex;
    align-items: center;
    gap: 12px;
}
.reportReasons span {
    background-color: #1E71F2;
    border-radius: 4px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reportReasons .checkBox {
    height: 20px !important;
    width: 20px !important;
    border-radius: 4px;
    background-color: white;
    border: 1px solid #383535;
}
.activeCheckbox {
    border-color: #1E71F2;
    background-color: #1E71F2;
}
.reportReasons p {
    font-size: 18px;
    font-weight: 400;
    color: #383535;
}
.repostModal::-webkit-scrollbar {
    display: none;
}
.repostModal h2 {
    font-weight: 500;
    font-size: 20px;
}
.modal h2 {
    font-size: 45px;
    color: #000;
    margin-bottom: 24px;
}
.modal h3 {
    font-size: 20px;
    font-weight: 400;
    color: #383535;
    margin-bottom: 40px;
}
.footerBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
 }
.footerBtns button {
    height: 48px;
    border-radius: 12px;
    border: 1px solid #2262C6;
    padding: 0px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}
.saveBtn {
    background-color: #2262C6;
    color: #fff;
}   
.cancelBtn {
    color: #2262C6;
    background-color: #fff;
}
/* ----------- */

.karmaUser {
    display: flex;
    align-items: center;
    gap: 20px;
}

.gaveKarmaIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    height: 84px;
    /* height: 65px; */
    /* width: auto; */
    /* object-fit: contain; */
    /* margin-left: auto; */
    /* margin-right: auto; */
}
.backgroundKarmaIcon {
    position: absolute;
    bottom: 8px;
    right: 4px;
    z-index: 1;
    opacity: 0.1;
    height: 75%;
}

/* edit and delete dropdown */
.editDeleteDropdown, .reportDropdown {
    position: absolute;
    left: -80px;
    bottom: -80px;
    height: 80px;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    background-color: #fff;
    display: block;
    margin-left: auto !important;
}
.editDeleteDropdown div,.reportDropdown div {
    padding: 8px 18px;
    cursor: pointer;
    text-align: left;
    transition: all .2s;
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
}
.editDeleteDropdown div:hover, .reportDropdown div:hover {
    background-color: #f9f9f9;
}
.editDeleteDropdown svg,.reportDropdown svg {
    width: 20px;
}
.reportDropdown {   
    height: 40px;
    bottom: -40px;
}
/* -------------------- */

.filterDropdown {
    width: 203px;
    padding: 20px;
    border-radius: 15px;
    background-color: white;
    position: absolute;
    top: 40px;
    right: 0px;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    z-index: 99999999;
}
.dropdownOverlay {
    position: fixed;
    height: calc(100vh + 10%);
    top: 0;
    left: 0;
    width: calc(100vw + 10%);
    background-color: transparent;
    z-index: 99999998;
}
.filterDropdown h2 {
    font-size: 16px;
    font-weight: 600;
    color: #383535;
    border-bottom: 1px solid #c8c8c8;
    padding-bottom: 8px;
    margin-bottom: 8px;
}
.filterDropdown input[type="radio"] {
    width: 16px;
    height: 16px;
}
.inputGroup {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}
.filterDropdown h3 {
    font-size: 14px;
    font-weight: 400;
    color: #383535;
}


.date p {
    color: #4E616C;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
}
.dateContent {
    display: flex;
    align-items: center;
    gap: 12px;
}
.dateContent h3 {
    font-size: 12px !important;
    font-weight: 500;
    color: #828282 !important;
}
.filterBtns {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.filterBtns p {
    font-size: 14px;
    color: #FF484E;
    font-weight: 400;
    cursor: pointer;
}
.filterBtns button {
    color: #2262C6;
    border: 1px solid #2262C6;
    background-color: transparent;
    border-radius: 6px;
    height: 24px;
    width: 50px;
    cursor: pointer;
}

.reportInformation {
    border: 2px solid #C62222;
    background-color: #FDDDDD;
    padding: 15px 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 24px;
}
.reportInformation p {
    color: #383535;
    font-size: 16px;
    font-weight: 400;
}
.reportInformation svg {
    cursor: pointer;
}

.pdfViewerContainer {
    display: flex;
    flex-direction: column;
    gap:8px
}
.pdfViewerContainer a {
    text-decoration: none;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 32px;
    width: fit-content;
    padding: 0px 18px;
    border-radius: 4px;
    background-color: #3c3c3c;
    border: none;
    color: #fff;
    font-size: 12px;
    gap:8px;
}
.pdfViewer {
    margin-top: 12px;
    width: 50%;
    height: 350px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.pdfViewer::-webkit-scrollbar {
    display: none;
}

.broadcast, .survey {
    padding: 20px 24px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    cursor: pointer;
    transition: all .2s;
    position: relative;
}
.broadcast {
    background: linear-gradient(to right, #48669b, #749bd9, #627da8, #6b82aa);
}
.survey {
    background: linear-gradient(to right,#1b7978, #6fb8b7,#1cadaa  );
}
.broadcastTag {
    background-color: #fff;
    color: #2262C6;
    border-radius: 16px 0px 0 16px;
    margin-right: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    padding: 0 18px;
    height: 38px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-left: auto;
    position: absolute;
    right: 24px;
    gap: 6px;
}
.broadCastHeader {
    display: flex;
    align-items: center;
    gap: 12px;
}
.broadCastHeader h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
}
.broadcastContent {
    margin-top: 18px;
}
.broadcastContent p {
    color: #fff;
    font-size: 16px;    
}
.broadcastTime {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 4px;
}
.broadcastTime p {
    color: #d9d9d9;
    font-size: 14px;
}


/* responsive design */
@media(max-width: 500px) {
    .container {
       min-height: calc(100vh - 60px);
       padding: 16px;
       margin-left: 0px !important;
       margin-top: 95px;
       display: grid;
       grid-template-columns: 100%;
   }
   .container main header {
       padding: 16px;
       margin-bottom: 20px;
       display: flex;
       align-items: flex-start;
       gap: 10px;
   }
   
   .profileIcon {
       width: 24px;
       height: 24px;
       background-color: transparent;
   }
   .profileIcon img {
       width: 24px;
       height: 24px;
   }
   .postInputArea {
        border-radius: 8px;
        min-height: 64px;
        padding: 8px;
        margin-bottom: 10px;
    }
    .titleInput {
        font-size: 16px;
        margin-bottom: 8px;
    }
    .postInputArea textarea, .descriptionInput {
        flex: 1;
        font-size: 14px;
    }
    .headerBtns {
        gap: 10px;
    }
    .headerBtns p {
        display: none;
    }
    .headerBtns button {
        height: 33px;
        width: 60px;
        border-radius: 8px;
        font-size: 14px;
        font-weight: 500;
    }
    .headerBtns img {
        height: 26px;
        width: 26px;
    }
    .profileIconSm {
        display: block;
    } 
    .profileIconLg {
        display: none;
    }
    .createPoll input {
        margin-bottom: 6px;
    }
    .filterBtn {
        margin-bottom: 20px;
    }
    .filterBtn h2 {
        font-size: 16px;
    }
    .tabs {
        margin-bottom: 12px;
    }
    .tabs div {
        height: 50px;
        font-size: 12px;
      }
    .activeTab {
        color: #2262C6 !important;
        background-color: #dee7f5 !important;
    }

    .card {
        padding: 12px;
        margin-bottom: 12px;
    }
    .cardHeader {
        gap: 14px;
    }
    .cardHeader img {
        height: 32px;
        width: 32px;
    }
    .cardHeader h3 {
        font-size: 14px;
    }
    .time {
        font-size: 12px;
    }
    .username {
        margin-bottom: 4px;
    }
    .online {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #01B829;
    }
    .cardBody {
        margin-bottom: 16px;
    }
    .cardBody h5 {
        font-size: 14px;
        font-weight: 600;
        color: #383535;
        margin-bottom: 6px;
    }
    .cardBody p {
        font-size: 14px;
    }
    .cardIcons {
        gap: 8px;
    }
    .cardIcons div {
        height: 24px;
    }
    .cardIcons p {
        font-size: 12px;
    }
    .cardFooter {
        gap: 14px;
    }
    .cardFooter div {
        font-size: 0px;
    }
    
    .commentsHeader {
        background-color: #e9eff9;
        font-size: 14px;
        margin-bottom: 12px;
        margin-top: 12px;
    }
    .comments form {
        gap: 10px;
    }
    
    .viewReplies {
        font-size: 10px;
    }
    .repliesOnComment {
        padding-left: 14px;
        margin-bottom: 14px;
    }
    .replyOnComment {
        margin-bottom: 12px;
    }
    
    .postImage {
        height: 200px;
        margin-top: 8px;
    }
    .postFile {
        width: 200px;
        margin-top: 12px;
        display: flex;
    }
    .modal {
        border-radius: 20px;
        padding: 0px;
        width: 80%;
        padding: 40px 0;
        z-index: 999999;
    }
    .repostModal {
        width: 84%;
    }
    .modal h2 {
        font-size: 20px;
    }
    .modal h3 {
        font-size: 16px;
    }
    .footerBtns button {
        height: 34px;
        border-radius: 8px;
        padding: 0px 18px;
        font-size: 12px;
    }
    .navOverlay {
        position: fixed;
        top: 0;
        left: 0;
        background-color:rgba(0,0,0,0.4);
        /* height: 100vh; */
        /* width: 100vw; */
        width: calc(100vw + 10%);
        height: calc(100vh + 10%);
        z-index: 99998;
    }
    .gaveKarmaIcon {
        display: none;
    }
    .reportModal {
        padding: 28px !important;
    }
    .reportReasons {
        gap: 10px;
    }
    .reportReasons div {
        gap: 10px;
    }
    .reportReasons p {
        font-size: 14px;
    }
    .pdfViewer {
        width: 100%;
        height: 200px;
    }
    .pdfViewerContainer a {
        margin-left: auto;
    }
    .broadcastTag {
        margin-right: -25px;
        font-size: 12px;
        padding: 6px 14px;
    }
    .broadcastContent p {
        font-size: 14px;
    }
    .broadcastTitle {
        font-size: 14px !important;
    }
}



/* for dropwdown -- */
.dropdownContainer {
    flex: 1;
}
.dropdown {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    height: 40px!important;
    width: 100% !important;
    padding: 0px 12px !important;
    border-radius: 4px !important;
    border: none !important;
    cursor: pointer !important;
    background-color: #fff !important;
    margin-bottom: 12px;
}
.dropdownPlaceholder {
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #828282 !important;
}
.dropdownMenu {
    display: flex !important;
    flex-direction: column !important;
    padding: 0 !important;
    margin: 0 !important;
    gap: 0 !important;
    align-items: stretch !important;
    border-radius: 6px !important;
    border: 1px solid #D0D1D9;
}
/* --------------- */

.checkbox {
    min-height: 16px;
    min-width: 16px;
    max-height: 16px;
    max-width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #000;
    cursor: pointer;
}
.activeCheckbox {
    background-color: #2262C6 !important;
    border-color: #2262C6 !important;
}