.container {
    padding: 24px;
    margin-left: 215px;
    margin-top: 76px;
}
.container main {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    border-radius: 10px;
}
.heading { 
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    background-color: #518EF8;
    padding: 12px 38px;
    border-radius: 10px;
}
.content {
    padding: 50px 48px;
}
.header {
    display: flex;
    align-items: center;
    gap: 17px;
    margin-bottom: 42px;
}
.header h2 {
    font-size: 28px;
    font-weight: 500;
    color: #2262C6;
    margin-bottom: 8px;
}
.header h3 {
    font-size: 22px;
    font-weight: 500;
    color: #8C8C8C;
}
.header h3 span {
    color: #518EF8 !important;
}
.headerImage {
    width: fit-content;
    border-radius: 50%;
    background-color: #d9d9d9;
    height: 98px;
    width: 98px;
    display: flex;
    justify-content: center;
    align-items: center;
} 

.cards {
    width: 85%;
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    align-items: center;
    justify-content: space-between;
    row-gap: 26px;
    margin-bottom: 16px;
}
.card {
    width: 181px;
    padding: 12px 20px;
    border: 1px solid #B2C8EB;
    border-radius: 10px;
    height: 100px;
}
.card h2 {
    font-size: 26px;
    font-weight: 600;
    color: #6A6A6A;
    margin-bottom: 14px;

}
.card h3 {
    font-size: 16px;
    color: #1F1F1F;
    font-weight: 500;
}
.card div {
    display: flex;
    align-items: center;
    gap: 9px;
}
.activity main {
    border: 1px solid #dedede;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    padding: 24px;
    border-radius: 10px;

}
.activity h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}