.container {
    padding: 24px;
    margin-left: 215px;
    margin-top: 76px;
    display: grid;
    grid-template-columns: calc(70% - 24px) 30%;
    justify-content: space-between;
    align-items: stretch;
}


/* chat box ------- */
.chatBox {
    position: relative;
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 76px - 48px);
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.chatBox header {
    background-color: #1E71F2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 54px;
    padding: 0px 30px;
    border-radius: 20px 20px 0 0;
}
.chatBox header h2 {
    color: #fff;
    font-size: 22px;
    font-weight: 400;
}
.chats {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 20px;
    height: 65vh;
    overflow-y: scroll;
}
.botchat div, .userchat div {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    gap: 14px;
}
.botchat h2, .userchat h2 {
    font-size: 24px;
    font-weight: 600;
    color: #1E71F2;
}
.botchat, .userchat {
    display: flex;
    flex-direction: column;
}
.botchat {
    align-items: flex-start;
}
.userchat {
    align-items: flex-end;
}
.botchat p, .userchat p {
    background-color: #F0F2F8;
    color: #1E71F2;
    font-size: 16px;
    font-weight: 400;
    padding: 12px;
    border-radius: 20px;
    width: fit-content;
}
.chatBox footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 75px;
    padding: 0px 20px;
    display: flex;
    align-items: flex-start;
    background-color: 0 0 20px 20px;
    gap: 20px;
}
.chatBox footer input {
    background-color: #F0F2F8;
    border-radius: 20px;
    border: none;
    outline: none;
    padding: 0px 24px;
    padding-left: 70px;
    height: 55px;
    width: 100%;
}
.chatBox footer input::placeholder {
    color: #606060;
}
.attachmentIcon, .micIcon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.attachmentIcon {
    left: 24px;
}
.micIcon {
    right: 28px;

}
.chats::-webkit-scrollbar {
    width: 8px;
    border-radius: 20px;
  }
   
.chats::-webkit-scrollbar-track {
    background-color: #fff !important;
    border: none !important;
    box-shadow: none !important;
}
   
.chats::-webkit-scrollbar-thumb {
    background-color: #ababab;
    border-radius: 20px;
}
.chatBox svg {
    cursor: pointer;
}
/* ---------- */

.sendBtn {
    background-color: #1E71F2;
    height: 55px;
    padding: 0px 20px;
    border-radius: 20px;
    font-size: 22px;
    font-weight: 500;
    cursor: pointer;
    color: #fff;
    border: none;
}