.card {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.card h2 {
  color: #383535;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}
.card ol {
  width: 70%;
  margin: 0 auto;
}
.card ol li {
  font-size: 14px;
  font-weight: 600;
  color: #383535;
  margin-bottom: 16px;
}
.card ol ul li {
  color: #828282;
  font-weight: 400;
}
.card ul {
  list-style: disc;
  margin-left: 16px;
}

@media (max-width: 500px) {
  .card {
    padding: 16px;
    padding-left: 30px;
  }
  .card h2 {
    font-size: 16px;
  }
  .card ol {
    width: 100%;
  }
  .card ol li {
    margin-bottom: 12px;
  }
}
