.container {
    background-color: #f4f7fa;
    padding: 20px 40px;
    margin-left: 120px;
    margin-top: 100px;
    min-height: calc(100vh);
  }
.header {
    margin-bottom: 21px;
    display: flex;
    gap: 10px;
    align-items: center;
    width: fit-content;
}
.header h2 {
    cursor: pointer;
    font-size: 30px;
    font-weight: 600;
    color: #2262C6;
}
.header svg {
    cursor: pointer;
}
.question {
    margin-bottom: 28px;
}
.tick {
    width: 266px;
    height: 266px;
    border-radius: 50%;
    background-color: #EEF4FE;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.modal p {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 40px;
}
.modal button {
    background-color: #2262C6;
    color: #fff;
    width: 242px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    height: 48px;
    border: none;
    font-size: 22px;
    font-weight: 600;
    cursor: pointer;
}

.tabs {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 30px;
}
.tabs div {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    color: #2262C6;
    background-color: #d3e0f4;
    border-radius: 4px;
    cursor: pointer;
    width: 218px;
}
.activeTab {
    color: #fff !important;
    background-color: #2262C6 !important;
}

.statistics h2 {
    width: 120px;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}
.statistics h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    opacity: 0.5;
}

.stats h3 {
    font-size: 30px;
    color: #2262C6;
    font-weight: 700;
    margin-bottom: 26px;
}
.stats h4 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
}

.statsCards {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 55px;
}
.statsCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 150px;
    width: 150px;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    padding: 24px 18px;
}
.statsCard h2 {
    font-size: 35px;
    font-weight: 600;
    max-height: 50px !important;
    min-height: 50px;
    width: fit-content !important;
}
.statsCard h3 {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #6A6A6A;
    margin: 0;
    
}

.pieChart div  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    width: 200px;
    gap: 22px;
}
.pieChart h4 {
    font-size: 20px;
    font-weight: 600;
    color: #374253;
}

/* for questions */
.question h3 {
    font-size: 20px;
    font-weight: 500;
    color: #000;
    margin-bottom: 14px;
}
.options {
    display: grid;
    grid-template-columns: 50% 50%;
    row-gap: 24px;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
    border: 1px solid #E0E0E0;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
.options div {
    display: flex;
    align-items: center;
    gap: 8px;
}
.options p {
    font-size: 16px;
    font-weight: 500;
    color: #686868;
}
.submitBtn {
    margin-top: 30px;
    background-color: #2262C6;
    color: #F0F2F8;
    height: 48px;
    border-radius: 20px;
    padding: 0px 30px;
    border: none;
    font-size: 22px;
    font-weight: 500;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    cursor: pointer;
}
.question textarea {
    width: 100%;
    outline: none;
    resize: none;
    padding: 16px;
    border: 1px solid #E0E0E0;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}
