.container {
    padding: 24px;
    margin-left: 215px;
    margin-top: 76px;
}
.header {    
    background-color: #2262C6;
    border-radius: 20px 20px 0 0;
    width: 100%;
    padding: 0px 26px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
}
.header h1 {
    font-size: 28px;
    font-weight: 600;
    color: #fff;
}
.circularCharts {
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    justify-content: space-between;
    align-items: center;
    row-gap: 40px;
    margin-bottom: 40px;
}
.circularChart h3 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 14px;
}
.circularChart div {
    padding: 36px;
    height: 240px;
    width: 240px;
    background-color: #f7faff;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.lineChartContainer h2 {
    font-size: 26px;
    font-weight: 500;
}
.lineChart {
    border: 1px solid #dedede;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
    padding: 24px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 40px;
}


.container aside {
    border: 1px solid #D8D8D8;
    border-radius: 20px;
    height: fit-content;
    margin-bottom: 30px;
}
.container aside header {
    font-size: 20px;
    font-weight: 600;
    color: #212121;
    padding: 22px;
    border-bottom: 1px solid #CCC6C6;
}
.container aside main {
    padding: 22px;
}
.container aside h2 {
    font-size: 17px;
    font-weight: 500;
    flex: 1;
    margin-left: 20px;
}
.container aside h3 {
    font-size: 22px;
    font-weight: 500;
}
.container aside main {
    display: grid;
    grid-template-columns: 40% 40%;
    justify-content: space-between;
    row-gap: 30px;
}
.container aside main div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.container aside main span {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #e2e2e2;
    border-width: 3px;
    border-style: solid;
}
.barCharts {
    display: grid;
    grid-template-columns: 48% 48%;
    justify-content: space-between;
    row-gap: 20px;
}
.barChart h2 {
    font-size: 24px;
    color: #6A6A6A;
    font-weight: 600;
    margin-bottom: 22px;
}
.barChart {
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    border-radius: 20px;
}
.barProgress {
    height: 45px;
    width: 100%;
    border: 2px solid #E4E4E4;
    border-radius: 20px;
    position: relative;
}
.barProgress:not(:last-child) {
    margin-bottom: 20px;
}
.barProgressInner {
    height: 100%;
    border-radius: 20px;
    width: 0px;
}
.barProgress p {
    font-size: 18px;
    font-weight: 400;
    color: #000;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
}