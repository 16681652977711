.overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: calc(100vh + 10%);
    width: calc(100vw + 10%);
    background-color: rgba(0,0,0,0.8);
    z-index: 999998;
    cursor: pointer;
}

.modal img {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 999999;
    width: auto;
    height: 90vh;
    object-fit: contain;
    display: block;
    border-radius: 10px;
}

@media(max-width:600px) {
    .modal img {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 999999;
        width: 90vw;
        height: auto;
        object-fit: contain;
        display: block;
        border-radius: 10px;
    }
}